import React, { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import useWindow from '../../../hooks/useWindow';
import { MainDescription } from '../../Typography';
import CustomRadioButton from './CustomRadioButton';

function RadioButtonGroup({ title, name, items, register, validationOptions, errors, handleInput, initialValue }) {
  const [currentRadioValue, setCurrentRadioValue] = useState()
  const { isDesktop } = useWindow();

  const generateItems = useCallback(() => {
    const components = [];
    items.forEach((item, index) => {
      components.push(
        <CustomRadioButton
          key={index}
          label={item.label}
          id={item.id}
          name={name}
          value={item.value}
          register={register}
          validationOptions={validationOptions}
          errors={errors}
          handleInput={handleInput}
          checked={item.checked || initialValue && currentRadioValue === item.value}
          setChecked={setCurrentRadioValue}
        />
      );
    });

    return components;
  }, [items, errors, handleInput, currentRadioValue, initialValue]);

  useEffect(() => {
    setCurrentRadioValue(initialValue)
  }, [initialValue])

  return (
    <div>
      <MainDescription fontSize="24px" fontWeight={!isDesktop ? 500 : 600}>
        {title}
      </MainDescription>
      <div className="radio-group__container">{generateItems()}</div>
    </div>
  );
}

export default RadioButtonGroup;
