import React from 'react';
import SEO from '../../components/SEO';
import imageIllustrationStepFirst from '../../assets/images/illustrations/RemortgagePage/step-image-first.png';
import imageIllustrationStepSecond from '../../assets/images/illustrations/overpayment-steps-second.png';
import imageIllustrationStepThird from '../../assets/images/illustrations/overpayment-steps-third.png';
import imageSetAside from '../../assets/images/illustrations/set-aside-image.png';
import imageShopWithSpriveCashbackVisual from '../../assets/images/illustrations/shop-with-sprive-cashback-visual.png';
import imageShopWithSpriveBrands from '../../assets/images/illustrations/shop-with-sprive-brands-no-bg.png';
import imageBarclaysLogo from '../../assets/images/logo/Barclays-logo.png';
import imageHSBCLogo from '../../assets/images/logo/HSBC-logo.png';
import imageNatWestLogo from '../../assets/images/logo/NatWest-logo.png';
import imageHallifaxLogo from '../../assets/images/logo/Halifax-logo.png';
import imageRBSLogo from '../../assets/images/logo/RBS-logo.png';
import imageNationWideLogo from '../../assets/images/logo/NationWide-logo.png';
import imageTestimonialsAbi1x from '../../assets/images/illustrations/abi-testimonials1x.png';
import imageTestimonialsAbi2x from '../../assets/images/illustrations/abi-testimonials1x.png';
import imageTestimonialsIndi1x from '../../assets/images/illustrations/indi-testimonials1x.png';
import imageTestimonialsIndi2x from '../../assets/images/illustrations/indi-testimonials2x.png';
import imageTestimonialsLynn from '../../assets/images/illustrations/lynn-testimonials.png';
import imageTestimonialsRob1x from '../../assets/images/illustrations/rob-testimonials1x.png';
import imageTestimonialsRob2x from '../../assets/images/illustrations/rob-testimonials2x.png';
import useWindow from '../../hooks/useWindow';
import SectionWrapper from '../../components/CommonSections/SectionWrapper';
import CustomHeadingSection from '../../components/CommonSections/CustomHeadingSection';
import ParagrphWithTitle from '../../components/ParagrphWithTitle';
import Heading from '../../components/Heading';
import { SecondaryDescription } from '../../components/Typography';
import { useCallback } from 'react';
import BrandsList from '../../components/BrandsList';
import SliderSection from '../../components/CommonSections/SliderSection';
import FAQSection from '../../components/FAQSection';
import DownloadAppSection from '../../components/CommonSections/DownloadAppSection';
import StepsHeading from '../../components/CommonSections/StepsHeading/index.jsx';
import CalculatorSection from '../../components/CommonSections/CalculatorSection';
import CustomTextField from '../../components/CommonSections/formElements/CustomTextField';
import {
  ImageContainer,
  OutputText,
  OutputTextContainer,
} from '../../components/CommonSections/CalculatorSection/OutputComponents';
import RadioButtonGroup from '../../components/CommonSections/formElements/RadioButtonGroup';
import useCalculators from '../../hooks/useCalculators';
import { useMemo } from 'react';
import { useState } from 'react';
import DotsLoader from '../../components/Loader';
import ContactWidget from '../../components/ContactWidget';
import BasicModal from '../../components/Modals/BasicModal';
import DownloadAppModal from '../../components/Modals/DownloadAppModal';
import { Link, navigate } from 'gatsby';
import { useEffect } from 'react';
import useSliderHeight from '../../hooks/useSliderHeight';
import useMobileNavigation from '../../hooks/useMobileNavigation';
import CustomImage from '../../components/Image/Image';
import Digit from './Digit';

const debounce = require('lodash.debounce');

const stepsData = [
  {
    image: imageIllustrationStepFirst,
    title: 'Step 1',
    description: (
      <>
        Enter your mortgage <br /> details below
      </>
    ),
  },
  {
    image: imageIllustrationStepSecond,
    title: 'Step 2',
    description: (
      <>
        See how much you could save <br /> by making overpayments
      </>
    ),
  },
  {
    image: imageIllustrationStepThird,
    title: 'Step 3',
    description: 'Download the Sprive app and start making overpayments',
  },
];

const dealsItems = [
  {
    image: imageBarclaysLogo,
  },
  {
    image: imageHSBCLogo,
  },
  {
    image: imageNatWestLogo,
  },
  {
    image: imageRBSLogo,
  },
  {
    image: imageHallifaxLogo,
  },
  {
    image: imageNationWideLogo,
  },
];

const FAQItems = [
  {
    title: 'What are mortgage overpayments?',
    description: (
      <>
        Mortgage overpayments are when a borrower pays more than their required monthly mortgage
        payment. This helps reduce the overall balance owed by applying the additional payment
        directly to the principal of the loan. For example, if the monthly mortgage payment is
        £1,000 and the borrower makes an overpayment of £200, £200 will be applied directly to the
        principal balance, reducing the overall amount owed on the loan.
      </>
    ),
  },
  {
    title: "What's the quickest way to pay off a mortgage?",
    description: (
      <>
        For most homeowners, our view is that the quickest way to pay off a mortgage is to download
        the Sprive app which will help you regularly make additional payments towards the principal
        balance without impacting your lifestyle.
        <br />
        <br />
        If you can afford to you can make a lump sum overpayment that's equal to your annual
        overpayment allowance. You can visualise the impact by using our online mortgage overpayment
        calculator.
      </>
    ),
  },
  {
    title: 'How much can I overpay each month?',
    description: (
      <>
        The amount you can overpay will depend on the lender you are with and the mortgage product
        you are on. The majority of lenders will allow you to pay up to 10% of the outstanding
        balance. However, this can vary and thus, it is better to view your mortgage offer document,
        check online or speak to your lender to be sure.
        <br />
        <br />
        You can find out how much you can overpay by asking your lender, checking your mortgage
        offer document, or checking online.
      </>
    ),
  },
  {
    title: 'Am I eligible to make overpayments?',
    description: (
      <>
        Eligibility for overpayments will depend on the terms of your mortgage agreement. Some
        mortgages may have restrictions on early repayment, while others may allow overpayments
        without penalty. Check with your lender or read your mortgage offer document to find out the
        specifics of your mortgage agreement.
      </>
    ),
  },
  {
    title: 'How can I make regular monthly overpayments?',
    description: <>Simply head to the app store and download the Sprive app.</>,
  },
  {
    title: 'What are the benefits of overpayments?',
    description: (
      <>
        Mortgage overpayments can help to reduce your mortgage balance, the overall amount of
        interest paid on a mortgage, shorten the loan term, and build equity in the property at a
        faster rate. You'll be amazed by the total interest you can save by making overpayments.
        This can also help to increase your credit score and decrease the likelihood of default.
        Additionally, paying down the loan balance faster can significantly reduce your financial
        stress and simultaneously free up cash flow, which can be used for other financial goals.
        <br />
        <br />
        Making overpayments can also help reduce your loan to value, which can increase your chances
        of securing a better deal when you next remortgage.
        <br />
        <br />
        You can visualise the impact by using our online mortgage overpayment calculator.
      </>
    ),
  },
  {
    title: 'What are the risks associated with overpayments?',
    description: (
      <>
        The main risk associated with overpayments is the potential for early repayment charges,
        which can be significant. Additionally, overpaying on a mortgage may not be the best use of
        funds if other financial priorities exist, such as building an emergency fund or paying down
        high-interest debt.
        <br />
        <br />
        It's important you can afford making the normal monthly payment associated with your
        mortgage before you make overpayments. Failing to make your mortgage repayments could result
        in your home being repossessed.
      </>
    ),
  },
  {
    title: 'Is there a penalty for making overpayments?',
    description: (
      <>
        If you overpay above the limits set by your lender, then they could charge you an early
        repayment charge. This charge will be on the amount you have overpaid. The banks typically
        set a 1% to 5% penalty to prevent you from repaying your mortgage too quickly.
      </>
    ),
  },
  {
    title: 'What are Early Repayment Charges?',
    description: (
      <>
        An early repayment charge is a penalty applied by the lender if you repay more on your
        mortgage than the permitted amount during your tie-in period. Early repayment charges are
        typically found on fixed and discounted variable rate mortgages. With the Sprive app you can
        track how much of your annual allowance you’ve used to help you prevent being hit by an
        early repayment charge.
      </>
    ),
  },
  {
    title: 'When are ERCs applicable?',
    description: (
      <>
        There are various scenarios where ERCs can apply, such as when you:
        <br />
        <br />
        <ul>
          <li>
            Make significant repayments – making a sizable overpayment on your mortgage that
            breaches the threshold outlined in your mortgage offer document would result in your
            lender applying an ERC penalty.
          </li>
          <li>
            Move home – if you are moving house, you may have to switch lenders. If you are part way
            through your initial deal period for your fixed or discounted deal then you are likely
            to be charged an ERC penalty.
          </li>
          <li>
            Switch mortgage deals early – you may decide that you want to move to a different
            mortgage product within the same lender or different lender whilst you are in your deal
            period. If that is the case you are likely to be subject to ERC.
          </li>
          <li>
            Pay off your mortgage – perhaps you’re approaching retirement and want to use your
            savings to pay off your mortgage with a lump sum or maybe you have received some
            inheritance. By paying the entire outstanding balance of your mortgage, you may be
            subject to large ERC penalties.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'How can I avoid Early Repayment Charges?',
    description: (
      <>
        <ul>
          <li>
            Choose a mortgage deal with no ERC (Early Repayment Charge) and with flexible
            overpayment options if you plan to pay more than 10% of your loan in a single year.
          </li>
          <li>
            Port your mortgage deal when moving home to apply the same deal and interest rate to the
            mortgage on your new property.
          </li>
          <li>
            Pay up to the overpayment limit (usually 10% of the loan value) without penalties, keep
            track of the total amount paid and check your mortgage offer document, or contact your
            lender to confirm the details. Consider waiting until the ERC period ends or switching
            from the SVR, which can be expensive, before overpaying large amounts.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'Is it better to make overpayments monthly or in lump sum?',
    description: (
      <>
        The choice between monthly overpayments and lump sum overpayments will depend on personal
        preference and financial circumstances. Making monthly overpayments can help to build equity
        over time and may be easier to budget for, while lump sum payments can provide a larger
        reduction in the overall balance and interest paid. If you’ve saved up a good amount of
        money that you’re willing to contribute to your mortgage, then a lump sum may be a good
        option for you!
      </>
    ),
  },
  {
    title: 'Is it smart to pay extra on your mortgage?',
    description: (
      <>
        Paying extra on a mortgage can be a smart financial decision if it is done strategically and
        does not come at the expense of other financial priorities. It can help to reduce the
        overall interest paid and shorten the loan term, ultimately saving you money in the long
        run.
        <br />
        <br />
        Use Sprive's smart overpayment calculator to find out how much money you could save in
        interest and how much earlier you could be mortgage-free.
      </>
    ),
  },
  {
    title: 'Is it better to pay the principal or interest?',
    description: (
      <>
        When making extra payments on a mortgage, it is typically better to pay towards the
        outstanding mortgage balance rather than the interest. This will help to reduce the overall
        balance owed and ultimately decrease the amount of interest paid over the life of the loan.
        <br />
        <br />
        Paying towards the principal balance reduces the interest that accrues on the loan, saving
        you money over the loan period. It also helps to build equity in the property at a faster
        rate, which can be beneficial if you decide to sell or refinance the property in the future.
      </>
    ),
  },
  {
    title: 'Is it better to overpay or reduce term?',
    description: (
      <>
        The answer to this question depends on your personal and financial circumstances. If you are
        seeking to become debt-free as quickly as possible and can afford the higher monthly
        payments, reducing your mortgage term may be the better option.
        <br />
        <br />
        However, if the goal is to reduce the overall interest paid and build equity at a faster
        rate while still having the flexibility to make extra payments as desired, overpaying may be
        the better choice. You can always use our overpayment calculator and the Sprive app to help
        you decide what is right for you.
        <br />
        <br />
        We recommend you should consider their individual circumstances and consult with a financial
        advisor to determine the best approach for their situation.
      </>
    ),
  },
];

const calculatorAssumptions = (
  <>
    Amount and time savings highlighted in this online calculator act as a guide and may vary based
    on your lender. In order to create the results we’ve made the following assumptions:
    <br />
    <br />
    <ul>
      <li>
        <p>Savings are calculated based on the mortgage information you have provided us</p>
      </li>
      <li>
        <p>Based on a repayment mortgage</p>
      </li>
      <li>
        <p>Overpayments will have the effect of reducing your mortgage term</p>
      </li>
      <li>
        <p>Interest is calculated daily and added monthly</p>
      </li>
      <li>
        <p>Monthly overpayments remain the same over the term of the mortgage</p>
      </li>
      <li>
        <p>Your rate of interest always stays the same</p>
      </li>
      <li>
        <p>
          No changes to your mortgage which would result in a recalculation of your monthly payment
        </p>
      </li>
      <li>
        <p>
          Estimations are provided on a non-advised basis. Click here to read our full{' '}
          <Link to="/terms">Terms & Conditions.</Link>
        </p>
      </li>
      <li>
        <p>
          *The total projected amount Sprive users are on track to save by making mortgage
          overpayments via the app. The savings are an estimated value as of the 30 th August 2023.
        </p>
      </li>
    </ul>
  </>
);

function OverpaymentCalculatorPage() {
  const { isDesktop, isMobile } = useWindow();
  const [interestMortgageData, setInterestMortgageData] = useState({
    leftToPay: null,
    leftYears: null,
    monthlyPayment: null,
  });
  const [savingMortgageData, setSavingMortgageData] = useState({
    affordableOverpay: 0,
    isLumpSum: false,
    lumpSum: 0,
  });
  const [interestCalculatorValue, setInterestCalculatorValue] = useState(null);
  const [savingCalculatorValue, setSavingCalculatorValue] = useState(null);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [firstModalOpen, setFirstModalOpen] = useState(false);
  const sliderHeight = useSliderHeight('611px', '162px', '172px');

  const calculatorRadioGroupItems = useMemo(
    () => [
      {
        label: 'Yes',
        value: true,
        id: 'yes-radio',
      },
      {
        label: 'No',
        checked: !savingMortgageData.isLumpSum && true,
        value: false,
        id: 'no-radio',
      },
    ],
    [savingMortgageData]
  );

  const calculatorsOptions = useMemo(
    () => [
      {
        fetchUrl: 'interest-calculator',
        getBody: ({ interestPayload }) => interestPayload,
        state: interestMortgageData,
        setState: setInterestMortgageData,
        fetchResult: interestCalculatorValue,
        setFetchResult: setInterestCalculatorValue,
      },
      {
        fetchUrl: 'saving-calculator',
        getBody: ({ newState, interestCalculatorValue, interestPayload }) => ({
          ...interestPayload,
          total_interest: interestCalculatorValue?.data?.total_interest,
          interest_rate: interestCalculatorValue?.data?.interest_rate,
          monthly_mortgage_overpayment: newState.affordableOverpay,
          lump_sum_overpayment: newState.lumpSum ? newState.isLumpSum : false,
          lump_sum_overpayment_amount: newState.lumpSum,
        }),
        state: savingMortgageData,
        setState: setSavingMortgageData,
        fetchResult: savingCalculatorValue,
        setFetchResult: setSavingCalculatorValue,
      },
    ],
    [interestMortgageData, interestCalculatorValue, savingMortgageData, savingCalculatorValue]
  );

  const { handleInput, totalInterest, estimatedTimeSaving, earlyCloseSaving, loadingIndex, error } =
    useCalculators(calculatorRadioGroupItems, calculatorsOptions);

  const generateBankCards = useCallback(() => {
    const components = [];

    dealsItems.forEach((item, index) => {
      components.push(
        <BankCard
          key={index}
          image={item.image}
          title={item.title}
          imageHeight={item.imageHeight}
          imageWidth={item.imageWidth}
          isMobile={isMobile}
        />
      );
    });

    return components;
  }, [isMobile]);

  useEffect(() => {
    if (isMobile && firstModalOpen) {
      useMobileNavigation();
    }

    if (isMobile && isDownloadModalOpen) {
      setFirstModalOpen(true);
      setIsDownloadModalOpen(false);
    }
  }, [isMobile, isDownloadModalOpen, firstModalOpen]);

  const sliderItems = useMemo(
    () => [
      {
        image: isMobile ? imageTestimonialsAbi1x : imageTestimonialsAbi2x,
        description: `Super useful app to help you pay off your mortgage more
        quickly - you can choose how much you overpay each month depending
        on how much money you have in your account.`,
        person: 'Abi Carter',
        personInfo: 'Chief Executive',
      },
      {
        image: isMobile ? imageTestimonialsIndi1x : imageTestimonialsIndi2x,
        description: `Thanks to Sprive I was able to both easily manage repayments,
        but also find great deals for remortgaging, which Sprive took the hassle out of too.`,
        person: 'Indi Rai',
        personInfo: 'Head of Marketing',
      },
      {
        image: imageTestimonialsLynn,
        description: `Sprive has been a game changer for me. I had never overpaid my mortgage before
        Sprive, and after a year I had overpaid by £600.`,
        person: 'Lynn Beattie',
        personInfo: 'Managing Director',
      },
      {
        image: isMobile ? imageTestimonialsRob1x : imageTestimonialsRob2x,
        description: `Sprive has changed the way I think about my mortgage.
        Being able to make payments to my lender in one-tap makes the app a no-brainer.`,
        person: 'Rob May',
        personInfo: 'Financial Advisor',
      },
    ],
    [isMobile]
  );

  return (
    <>
      <SEO
        description="See what you could save from mortgage overpayments with Sprive. You can pay off your mortgage while you shop by setting money aside automatically"
        title="Mortgage Overpayment Calculator - Sprive"
      />
      <div className="common-section overpayment common-section-title">
        <section className="overpayment__first-section">
          <StepsHeading
            showDigit={true}
            title={
              <>{isMobile ? <>Overpayment Calculator</> : <>Mortgage Overpayment Calculator</>}</>
            }
            subtitle={
              <>
                {isMobile ? (
                  <>See what you could save from mortgage overpayments</>
                ) : (
                  <>See what you could save from mortgage overpayments in 3 easy steps.</>
                )}
              </>
            }
            stepsData={stepsData}
            handleClick={() => setIsDownloadModalOpen(true)}
          />
        </section>

        <CalculatorSection
          title={<>Tell us about your current mortgage</>}
          leftContent={
            <div className="calculator-inputs">
              <CustomTextField
                label="£"
                title="How much do you have left to pay on your mortgage?"
                name="leftPay"
                handleInput={debounce((e) => handleInput(e, 'leftToPay', 0), 300)}
                errors={error && { leftPay: error.leftToPayError || error.invalidData }}
              />
              <CustomTextField
                label="Years"
                title="How many years are left on the mortgage?"
                name="leftYears"
                reversed={true}
                handleInput={debounce((e) => handleInput(e, 'leftYears', 0), 300)}
                errors={error && { leftYears: error.leftYearsError || error.invalidData }}
              />
              <CustomTextField
                label="£"
                title="How much is your monthly mortgage payment?"
                name="monthlyPayment"
                handleInput={debounce((e) => handleInput(e, 'monthlyPayment', 0), 300)}
                errors={error && { monthlyPayment: error.monthlyPaymentError || error.invalidData }}
              />
              {error && error.invalidData && error.fetchError === false && (
                <div className="error-message">
                  <SecondaryDescription>
                    Invalid amount. Please re-check the amount entered.
                  </SecondaryDescription>
                </div>
              )}
              {error && error.fetchError === 0 && (
                <div className="error-message">
                  <SecondaryDescription>
                    Oops, something went wrong.
                    <br />
                    Please try again later
                  </SecondaryDescription>
                </div>
              )}
            </div>
          }
          rightContent={
            <>
              <ImageContainer>
                <OutputText
                  title="Estimated interest you’ll pay your lender"
                  value={loadingIndex === 0 ? <DotsLoader /> : totalInterest}
                />
              </ImageContainer>
            </>
          }
          withSeparator
        />
        <CalculatorSection
          title={
            <>Let’s see what making overpayments {!isMobile && <br />} could do for your mortgage</>
          }
          leftContent={
            <div className="calculator-inputs">
              <CustomTextField
                label="£"
                title="What could you afford to overpay on your mortgage each month?"
                name="affordableOverpay"
                handleInput={debounce((e) => handleInput(e, 'affordableOverpay', 1), 300)}
                errors={error && { affordableOverpay: error.affordableOverpayError }}
              />
              <RadioButtonGroup
                title="Would you like to include a lump sum overpayment too?"
                items={calculatorRadioGroupItems}
                name="first-group"
                handleInput={(e) => handleInput(e, 'isLumpSum', 1)}
              />
              {savingMortgageData.isLumpSum && (
                <CustomTextField
                  label="£"
                  title="How much do you want to pay as a lump sum overpayment?"
                  name="lumpSum"
                  handleInput={debounce((e) => handleInput(e, 'lumpSum', 1), 300)}
                  errors={error && { lumpSum: error.lumpSumError }}
                />
              )}
              {error && error.fetchError === 1 && (
                <div className="error-message">
                  <SecondaryDescription>
                    Oops, something went wrong.
                    <br />
                    Please try again later
                  </SecondaryDescription>
                </div>
              )}
            </div>
          }
          rightContent={
            <OutputTextContainer>
              <OutputText
                title="How much you could save in interest"
                value={loadingIndex === 1 ? <DotsLoader /> : earlyCloseSaving}
              />
              <OutputText
                title="How much earlier you could be mortgage-free"
                value={loadingIndex === 1 ? <DotsLoader /> : estimatedTimeSaving}
              />
            </OutputTextContainer>
          }
          noPadding
          dropdownText={calculatorAssumptions}
          handleClick={() => setIsDownloadModalOpen(true)}
          disclamerText={`
              The calculator is for illustrative purposes only. It does not provide a precise
              calculation of the time or interest you could save, but is intended to give you
              information and guidance about the potential savings. Please note the calculator
              does not factor in any charges for early repayments of the total mortgage balance
              and is based on a capital repayment mortgage.
          `}
        />
        <section className="overpayment__second-section">
          <SectionWrapper>
            <Heading title={<>Save money on your mortgage with Sprive</>} />
          </SectionWrapper>
          <div className="money-aside-container">
            <SectionWrapper>
              <CustomHeadingSection
                leftContent={
                  <div className="money-aside-text">
                    <ParagrphWithTitle
                      title={
                        <>
                          Set aside money <br /> automatically
                        </>
                      }
                      firstParagraph="Our algorithm analyses your spending each week and sets aside the perfect mortgage overpayment amount."
                      secondParagraph="The less you spend, the more we set aside without ever impacting your lifestyle. Mortgage overpayments can be made in just one tap."
                    />
                  </div>
                }
                rightContent={<>{isMobile && <CustomImage src={imageSetAside} />}</>}
              />
            </SectionWrapper>
            {!isMobile && (
              <div>
                <CustomImage src={imageSetAside} />
              </div>
            )}
          </div>
          <SectionWrapper noPadding>
            <CustomHeadingSection
              leftContent={
                <>
                  {!isMobile && (
                    <CustomImage
                      src={imageShopWithSpriveCashbackVisual}
                      style={{ maxWidth: '100%', height: '100%' }}
                    />
                  )}
                </>
              }
              rightContent={
                <div className="pay-off__container">
                  <ParagrphWithTitle
                    title={
                      <>
                        Pay off your mortgage <br /> while you shop
                      </>
                    }
                    firstParagraph="Do your shopping with Sprive and we'll give you extra cash to put towards your mortgage the same day. You'll be amazed at the difference it makes!"
                    secondParagraph="We've partnered with the UK's leading brands."
                  />
                  <BrandsList brandsImage={imageShopWithSpriveBrands} withoutContainer />
                </div>
              }
            />
            {!isMobile && <span className="separator" />}
            <CustomHeadingSection
              leftContent={
                <ParagrphWithTitle
                  title={
                    <>
                      Get better <br /> mortgage deals
                    </>
                  }
                  firstParagraph="We compare your mortgage across 90+ lenders every day to help you find your next deal."
                  secondParagraph="You can see personalised deals within the app and when it’s time for you to remortgage we’ll help you switch hassle-free, by connecting you with a vetted expert."
                />
              }
              rightContent={<div className="bank-card__container">{generateBankCards()}</div>}
            />
          </SectionWrapper>
        </section>
        <SliderSection sliderItems={sliderItems} customHeight={sliderHeight} />
        <div className='calculator-page-title'>
          <FAQSection
            title={
              <>
                Have a question{" "}
                {isMobile && <br />}
                about overpayments?
                <br />
                Check out our FAQ’s
              </>
            }
            FAQItems={FAQItems}
          />
        </div>
        <DownloadAppSection />
        <ContactWidget
          handleClick={() => setIsDownloadModalOpen(true)}
          title={
            <>
              Download App <br /> for FREE
            </>
          }
          downloadIcon
        />
        <BasicModal
          isOpen={!isMobile && isDownloadModalOpen}
          closeHandler={() => setIsDownloadModalOpen(false)}
        >
          <DownloadAppModal />
        </BasicModal>
      </div>
    </>
  );
}

const BankCard = ({ image, imageHeight, imageWidth, isMobile }) => {
  return (
    <div className="bank-card">
      <CustomImage
        src={image}
        style={{
          height: `${imageHeight && imageHeight[isMobile ? 1 : 0]}px`,
          width: `${imageHeight && imageWidth[isMobile ? 1 : 0]}px`,
        }}
      />
    </div>
  );
};

export default OverpaymentCalculatorPage;
