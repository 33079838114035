import React from 'react';
import useWindow from '../hooks/useWindow';
import { SecondaryDescription } from './Typography';

function ParagrphWithTitle({ title, firstParagraph, secondParagraph }) {
  const { isMobile } = useWindow();

  return (
    <div className="paragraph-with-title">
      <h3>{title}</h3>
      {isMobile ? (
        <SecondaryDescription>{`${firstParagraph} ${secondParagraph}`}</SecondaryDescription>
      ) : (
        <>
          <SecondaryDescription>{firstParagraph}</SecondaryDescription>
          <SecondaryDescription>{secondParagraph}</SecondaryDescription>
        </>
      )}
    </div>
  );
}

export default ParagrphWithTitle;
