import React from 'react';

function CustomRadioButton({ id, label, name, value, register, validationOptions, errors, handleInput, checked, setChecked }) {
  return (
    <label htmlFor={id} onClick={() => setChecked(value)}>
      <div
        className="custom-radio__container"
        style={{
          border: (errors && errors[name]) ? '1px solid #da2972' : null,
        }}
      >
        <input
          type="radio"
          id={id}
          name={name}
          value={label}
          checked={checked}
          onChange={() => handleInput(label)}
          {...(register && register(name, validationOptions))}
        />
        <p className="custom-radio-label">{label}</p>
      </div>
    </label>
  );
}

export default CustomRadioButton;
